<template>
  <div>
    <nav
      v-if="isLoggedIn && isInitiated"
      class="navbar-custom-menu"
    >
      <div class="navbar-item">
        <router-link
          :to="{ name: 'Dashboard'}"
          class="navbar-link icon-text is-arrowless"
        >
          <span class="icon is-small">
            <i class="fas fa-chevron-left"></i>
          </span>
          <span>Passport</span>
        </router-link>
      </div>
    </nav>
    <hr />

    <div class="contentWrapper">
      <h2 class="title is-3">Your vouchers</h2>
      <p>Find and manage your vouchers.</p>
      <hr class="separation" />
      <div class="padding has-background-grey-light">
        <div class="columns">
          <div class="column">
            <div v-if="isLoggedIn">
              <label class="checkbox">
                <input
                  type="checkbox"
                  v-model="inputShowExpiredVouchers"
                />
                Show expired vouchers
              </label>
            </div>
          </div>
        </div>
      </div>
      <hr class="separation" />
      <!-- <transition> -->
      <template v-if="vouchers.length > 0">
        <div
          v-for="voucher in vouchers"
          :key="voucher.Id"
          class="media voucher-media"
        >
          <figure class="media-left">
            <i class="is-6 s2m-icon-label"></i>
          </figure>
          <div class="media-content">
            <div class="content voucher-content">
              <div class="columns is-multiline">
                <div class="column is-full-mobile is-two-thirds-tablet">
                  <strong>Voucher name</strong>
                  <br />
                  {{voucher.Name}}
                </div>
                <div class="column is-full-mobile is-one-third-tablet">
                  <strong>Voucher code:</strong>
                  <br />
                  {{voucher.Code}}
                </div>
                <div class="column">
                  <strong>Date</strong>
                  <br />
                  Valid from {{voucher.ValidFrom | dateFormat('en', 'shortDateFormat')}} until {{voucher.ValidUntil | dateFormat('en', 'shortDateFormat')}}
                </div>
              </div>
            </div>
          </div>
          <button
            class="button is-primary"
            @click="showVoucherDetail(voucher)"
          >
            <span class="icon">
              <i class="far fa-file-alt"></i>
            </span>
          </button>
        </div>
      </template>
      <!-- </transition> -->
      <hr class="separation" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import voucher from '@/providers/voucher'

export default {
  data() {
    return {
      inputShowExpiredVouchers: false,
      isLoading: false,
      showExpiredVouchers: false,
      textLabels: {
        seatType_1: 'Meetingspace',
        seatType_2: 'Workspace'
      },
      vouchers: {
        type: Object,
        default() {
          return {}
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      connectionStatus: 'getConnectionStatus',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      profile: 'getProfile'
    })
  },

  created() {
    window.scrollTo(0, 0)
    this.getVouchers()
  },

  beforeDestroy() {
    this.$store.commit('setSelectedPublicProfile', {
      profileId: 0,
      view: 'detail'
    })
  },

  watch: {
    profile() {
      this.getVouchers()
    },

    inputShowExpiredVouchers() {
      this.getVouchers()
    }
  },

  methods: {
    getVouchers() {
      voucher.methods
        .getProfileVouchers(
          0,
          0,
          0,
          this.profile.Id,
          '',
          this.inputShowExpiredVouchers,
          true,
          1,
          10
        )
        .then(response => {
          this.vouchers = response.data.Results
        })
    },
    showVoucherDetail(voucher) {
      this.$store.commit('setSelectedVoucher', voucher)
    }
  }
}
</script>

<style lang="scss" scoped>
.voucher-media {
  .content {
    overflow: hidden;
    .columns {
      margin-top: -2px;
      margin-bottom: -2px;
      .column {
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }
  }
}
</style>
